import Products from '../products/products';

const Testimonials = [
    {
        company: 'Port of Kimberling',
        author: 'Marina Manager',
        logoSrc: '/logos/testimonials/pok_logo.png',
        imgName: 'pokLogo',
        products: [Products.DRY_STACK.name],
        quote: 'In just one season, we were able to streamline our operations, both from an administrative perspective and on the ground with our forklift operators and managers. Our customers appreciate the convenience of being able to request their boat out on their schedule, instead of only during our office hours.',
    },
    {
        company: 'Deep Creek Marina',
        author: 'Manager',
        logoSrc: '/logos/testimonials/dcm-logo.png',
        imgName: 'dcmLogo',
        products: [Products.DRY_STACK.name],
        quote: 'We love using SpeedyDock. It has made launching boats from our high and dry storage a breeze! It has given us the ability to have customer request their boats out of high and dry themselves. These requests go directly to the person in charge of launching and it has eliminated miscommunications from the service advisor to the launcher.',
    },
    {
        company: 'F3 Marina Fort Lauderdale',
        author: 'Marina Manager',
        logoSrc: '/logos/testimonials/f3-marina-fl.png',
        imgName: 'f3MarinaFlLogo',
        products: [Products.DRY_STACK.name],
        quote: 'SpeedyDock offers a great combination of ease and efficiency for our boaters to request their vessels, and also for our staff to manage the various launch reservations being made throughout the day.',
    },
    {
        company: 'Harbour Cove Marina',
        author: 'Marina Manager',
        logoSrc: '/logos/testimonials/hc-logo.png',
        imgName: 'hcLogo',
        products: [Products.DRY_STACK.name],
        quote: 'SpeedyDock is great. It helped so much last year. The increased efficiency and turn around time savings from skipping the office and going to our drivers really improved our process. I don\'t know what I\'d do without it.',
    },
    {
        company: 'Baytowne Marina',
        author: 'Dockmaster',
        logoSrc: '/logos/testimonials/baytowne-logo.png',
        imgName: 'btmLogo',
        products: [Products.DRY_STACK.name],
        quote: 'Our marina had a very successful year last year and I attribute a lot of that success to this system. By sharing the system with multiple people at the marina and being able to access it on our phones, we were able to book boats more efficiently. I don\'t know what how we did without it before.',
    },
    {
        company: 'Russell Marine - Kowaliga',
        author: 'Office Manager',
        logoSrc: '/logos/testimonials/russell-marine.png',
        imgName: 'rmLogo',
        products: [Products.DRY_STACK.name],
        quote: 'Really can’t tell you how much we enjoy working with your program. It certainly did simplify our launching program for us.',
    },
    /*{
        company: 'Seattle Boat Company',
        author: 'CFO',
        logoSrc: '/logos/testimonials/seattle-boat.png',
        imgName: 'sbcLogo',
        products: [Products.ONLINE_SALES.name],
        quote: 'The integration with Shopify saves a ton of time. It just makes sense.',
    },*/
    {
        company: 'Milltown Marina',
        author: 'Manager',
        logoSrc: '/logos/testimonials/milltown.png',
        imgName: 'milltownLogo',
        products: [Products.DRY_STACK.name],
        quote: 'Best APP ever and exceptional customer service.',
    },
    {
        company: 'Seabreeze Marina Boat Club',
        author: 'Manager',
        logoSrc: '/logos/testimonials/seabreeze.png',
        imgName: 'seabreezeLogo',
        products: [Products.DRY_STACK.name, Products.BOAT_CLUB.name],
        quote: 'This APP has really transformed our marina and the way we run things! It is very easy to use and our customers love it! Customer Service is top notch. Travis, has been amazing to work with. He is very reliable and is always open to helping with any questions or concerns we may have. Would 100% recommend this APP.',
    },
    {
        company: 'Gordy\'s Marine',
        author: 'Marina Manager',
        logoSrc: '/logos/testimonials/gordys.png',
        imgName: 'gordysLogo',
        products: [Products.DRY_STACK.name],
        quote: 'SpeedyDock worked very well this season. We\'re happy with it and our customers really liked using the app.',
    },
    {
        company: 'Russell Marine - The Ridge',
        author: 'Office Manager',
        logoSrc: '/logos/testimonials/russell-marine.png',
        imgName: 'rmLogo',
        products: [Products.DRY_STACK.name],
        quote: 'We ABSOLUTELY love SpeedyDock!!!!!',
    },
    {
        company: 'Nauti-Club',
        author: 'Manager',
        logoSrc: '/logos/testimonials/nauti.png',
        imgName: 'nautiLogo',
        products: [Products.BOAT_CLUB.name],
        quote: 'When we started searching for a solution for our club we struggled to find something that fit even 50% of our needs. SpeedyDock covers 99% of what we were looking to do with our boat club and we are very happy with it.',
    },
    {
        company: 'Riveredge Marina',
        author: 'Office Manager',
        logoSrc: '/logos/testimonials/riveredge.png',
        imgName: 'riverEdgeLogo',
        products: [Products.DRY_STACK.name],
        quote: 'Our employees picked up on the system quickly and I have heard nothing but positive comments about SpeedyDock from our customers. It’s been great to get started with this season!',
    },
    {
        company: 'Keelforce',
        author: 'Owner',
        logoSrc: '/logos/testimonials/keelforce.png',
        imgName: 'keelforceLogo',
        products: [Products.DRY_STACK.name],
        quote: 'It\'s the ticket! It\'s easy to use and people like it.',
    },
    //{
    //    company: 'Loggerhead Marinas – Riviera Beach',
    //    author: 'Manager',
    //    logoSrc: '/logos/testimonials/loggerhead.png',
    //    imgName: 'rmLogo',
    //    products: [Products.DRY_STACK.name],
    //    quote: 'We ABSOLUTELY love SpeedyDock!!!!!',
    //},
];

export default Testimonials;